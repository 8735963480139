import React, { useState, useRef, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import UCookies from 'universal-cookie'
import useTranslation from 'next-translate/useTranslation'
import Layout from '@/components/Common/Layout/Layout'
import AuctionCard from '@/hoc/components/Auction/AuctionCard'
import styles from './auction/auction.module.scss'
import { useRouter } from 'next/router'
import {
  getDeviceType,
  pushDataLayerEventHandler,
  updateAuctionData,
  isMobileSSR,
  objectRemoveEmptyData,
} from '@/utils/utils'
import { ALL_SELLER_TYPE, getDropdownFilters, getFilterTypes, socket } from '@/utils/constant'
import Image from 'next/image'
import LoaderImage from '../assets/images/loader.gif'
import IconContact from '@/assets/icons/IconContact.js'
import TermsNConditions from '@/hoc/components/MenuModal/TermsNConditions'
import Pagination from '@/components/Common/Pagination'
import AutoSearchDropDownNew from '@/components/Common/AutoSearchDropDownNew'
import IconoGraphy from '@/components/Common/IconoGraphy'
import ContactUs from '@/hoc/components/Auction/ContactUsRevamp'
import SkeletonTags from '@/hoc/components/Auction/Skeleton/SkeletonTags'
import { getSavedAuctionsProperties } from '@/utils/common-methods'
import HowAuctionsWork from '@/hoc/components/MenuModal/HowAuctionsWork'
import DropDown from '@/components/Common/DropDown'
import { setTag } from '@sentry/nextjs'
import Head from 'next/head'
import { getImageLinks } from '@/utils/auctionUtils'
import { observerService } from '@/utils/observer'
import AuctionProvider from '@/lib/AuctionProvider'
import { getHeaderFooterLinks } from '@/services/headerService'
import EWalletFAQ from '@/components/EWallet/EWalletFAQ'
import { checkStaticURL } from './api/cms/cmsService'

const filterTypes = getFilterTypes() // this function returns types of filters we need to fetch from backend API
// this is the object which has request data to send filter types to backend
const filtersReqData = {
  types: filterTypes,
}

const App = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [headerReachedTop, setHeaderReachedTop] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false)
  const dropRef = useRef()
  const auctionHeaderRef = useRef()
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1199 })
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const dropdownBody = 'ghostDropdownBody'
  const toggleDropdown = () => {
    setOpen(!isOpen)
  }
  const cookies = new UCookies()
  const [isOpenAppHeader, setIsOpenAppHeader] = useState(null)
  useEffect(() => {
    if (isMobile || isTablet) {
      setIsOpenAppHeader(window.sessionStorage.getItem('isOpenHeaderClosed') != null)
    } else {
      setIsOpenAppHeader(true)
    }
  }, [typeof window != 'undefined' && window.sessionStorage])
  // close dropdown on outside or escape click
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && dropRef.current && !dropRef.current.contains(e.target)) {
        setOpen(false)
      }
    }
    const closeDropdown = (e) => {
      if (e.keyCode == 27) {
        setOpen(false)
      }
    }
    window.addEventListener('keydown', closeDropdown)
    document.addEventListener('click', checkIfClickedOutside)
    return () => {
      window.removeEventListener('keydown', closeDropdown)
      document.removeEventListener('click', checkIfClickedOutside)
    }
  }, [isOpen])

  const getStaticpageData = async () => {
    let staticpageData = await fetch(`/api/cms/staticpage?lang=${locale}&slug=terms-n-conditions-for-auction`)
    staticpageData = await staticpageData.json()
    setHtmlContent(staticpageData?.data?.body_content._html)
    setCssContent(staticpageData?.data?.head_content._html)
  }

  const faqsData = async () => {
    const queryParams = {
      slug: "auction-faq",
      locale: `${locale}${locale === 'ar' ? '-SA' : ''}`,
    };
    const URL = checkStaticURL('auctionfaq', queryParams);
    const faqRes = await fetch(URL)
    const faqResponse = await faqRes.json()
    setFaqs(faqResponse?.data?.entity_value)
  }

  useEffect(() => {
    setTag('page_type', 'AuctionSRP')
    setTag('device_type', getDeviceType())
    if (sessionStorage.getItem('scrollPositionGDP')) {
      sessionStorage.removeItem('scrollPositionGDP')
    }
    getStaticpageData()
    faqsData()
    getAuctionFilters()
    const observer = observerService.observable().subscribe((val) => {
      if (val?.data?.type == 'home') {
        fetchAuctions()
        setFiltersFromRoute(filterDropDowns)
      }
    })

    return () => observer.unsubscribe()
  }, [])

  const [modalType, setModalType] = useState(null)

  const onBtnClick = (type) => {
    setModalType(type)
  }

  const popupHandler = () => {
    setModalType(null)
    setOpen(false)
  }

  const { menuHeader, homeFooter, locale, auctionCollection, isDesktopSSR } = props
  const { t, lang } = useTranslation('translations-auction')
  const filterDropDowns = getDropdownFilters(t)
  const [filtersFromAPI, setFiltersFromAPI] = useState() // this is used to set the received filters from client side. By default they are set to server side filters
  const [defaultFilters, setDefaultFilters] = useState([]) // this is the default state of filters which is used to reset filter on clear button
  const [filterDropdown, setFilterDropdown] = useState([]) // this state is used for rendering filters with updated keys
  const [userSelectedCity, setUserSelectedCity] = useState({})
  const [savedAuctions, setSavedAuctions] = useState([])
  const [followedAuctions, setFollowedAuctions] = useState(null)
  const [loadingFollowedAuction, setLoadingFollowedAuction] = useState(true)
  const [termData, setTermData] = useState([])
  const [htmlContent, setHtmlContent] = useState('')
  const [faqs, setFaqs] = useState('')
  const [cssContent, setCssContent] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [auctionList, setAuctionList] = useState(auctionCollection?.auctions ?? [])
  const [sellerType, setSellerType] = useState(auctionCollection?.seller)
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const [page, setPage] = useState(router.query.page ? Number(router.query.page) : 1)
  const [count, setCount] = useState(auctionCollection?.count ?? 0)
  const [selectedFilterDropdown, setSelectedFilterDropdown] = useState({})
  const fetchAuctions = async (typeSeller, status, cityId = null) => {
    setLoading(true)
    await getAllSavedAuctionsProperties()
    setAuctionList([])
    let objectToSend = {
      type: status ? status.split(',') : [],
      page: router.query.page ? Number(router.query.page) - 1 : 0,
      pageSize: 15,
      cityId: cityId === 'ALL' ? null : cityId,
      seller: typeSeller || ALL_SELLER_TYPE.ALL,
    }
    if (cityId == 272) {
      delete objectToSend.cityId
    }
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_AUCTION_API_URL_V2}auction-srp-list?${new URLSearchParams(
        objectRemoveEmptyData(objectToSend),
      )}`,
      {
        headers: {
          'Content-Type': 'application/json',
          locale: lang,
        },
      },
    )
    const response = await res.json()
    if (response?.data) {
      setAuctionList(response.data.auctions)
      setSellerType(response.data.seller)
      setCount(response.data.count)
      setPage(router.query.page ? Number(router.query.page) : 1)
    } else {
      setAuctionList([])
      setSellerType('')
      setPage(0)
    }
    setLoading(false)
    //}
  }

  async function getAllSavedAuctionsProperties() {
    if (!cookies.get('authToken')) {
      setLoadingFollowedAuction(false)
      return
    }
    let allSavedAuctionsProperties = await getSavedAuctionsProperties(lang, ['followed_auction'])
    setFollowedAuctions(allSavedAuctionsProperties['followed_auction'])
    setLoadingFollowedAuction(false)
  }

  const getSrpDetails = (socketData) => {
    const index = auctionList.findIndex((item) => item.id == socketData.id || item.id == socketData.wasaltAuctionId)
    if (index == -1) {
      return
    } else {
      let tempList = [...auctionList]
      tempList[index] = {
        ...tempList[index],
        ...socketData,
        endDate: socketData?.end_date || socketData?.endDate,
        cancelDate: socketData?.cancelDate,
        serverTime: socketData.serverTime,
        leftOverMs: socketData?.leftOverMs,
        leftOverServerMs: socketData?.leftOverServerMs,
      }
      setAuctionList(tempList)
    }
  }

  const handleAuctionEditMode = (socketInfo) => {
    const index = auctionList.findIndex((item) => item.id == socketInfo.id);
    if (index == -1) {
      return
    } else if (socketInfo?.status === 'awaiting_rejected') {
      let tempList = [...auctionList]
      tempList[index] = {
        ...tempList[index],
        status: 'awaiting-rejected',
      }
      setAuctionList(tempList)
      return;
    } else {
      if (!socketInfo?.isAuctionGroupEditModeOn) {
        router?.reload()
        return ;
      }
      let tempList = [...auctionList]
      tempList[index] = {
        ...tempList[index],
        isEditing: socketInfo?.isAuctionGroupEditModeOn,
      }
      setAuctionList(tempList)
    }
  }

  const socketConnection = () => {
    socket.on('connect', () => null)
    socket.emit('auction:join', { roomName: 'auction_srp' })
  }

  useEffect(() => {
    if (auctionList?.length > 0) {
      socketConnection()
      socket.on('auctionGroup:extended', getSrpDetails)
      socket.on('auctionGroup:started', getSrpDetails)
      socket.on('auctionGroup:closed', getSrpDetails)
      socket.on('private:started', getSrpDetails)
      socket.on('private:closed', getSrpDetails)
      socket.on('private:extended', getSrpDetails)
      socket.on('auctionGroup:unapproved', handleAuctionEditMode);
      socket.on('auctionGroup:action', handleAuctionEditMode);

      socket.io.on('error', (error) => {
        console.log('error', error)
      })

      socket.on('disconnect', () => {
        socketConnection()
      })

      socket.on('connect_error', () => {
        console.log('connect_error')
        socketConnection()
      })
    }
    return () => {
      socket.emit('auction:exit', { roomName: 'auction_srp' })
      socket.off('auctionGroup:extended', getSrpDetails)
      socket.off('auctionGroup:started', getSrpDetails)
      socket.off('auctionGroup:closed', getSrpDetails)
      socket.off('private:started', getSrpDetails)
      socket.off('private:closed', getSrpDetails)
      socket.off('private:extended', getSrpDetails)
      socket.off('auctionGroup:unapproved', () => null)
      socket.off('auctionGroup:action', () => null);
      socket.off('error', (error) => {
        console.log('error', error)
      })
      socket.off('reconnect', () => null)
      socket.off('connect')
    }
  }, [auctionList])

  const getAuctionData = async () => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_AUCTION_URL}auction-tc`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        authorization: cookies.get('authToken') ?? '',
      },
    })
    const response = await res.json()
    if (response.data) {
      let termDataProp = response.data.map((data) => data)
      setTermData(termDataProp)
    } else {
      setTermData('')
    }
  }

  /**@author Vikrant Sharma */
  // This function is used to make API call for fetching filters on client side
  const getAuctionFilters = async () => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V1}type-masters`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        authorization: cookies.get('authToken') ?? '',
      },
      body: JSON.stringify(filtersReqData),
    })
    const response = await res.json()
    if (response.data) {
      setFiltersFromAPI(response.data)
    }
  }

  const [cityData, setCityData] = useState([])

  const getCityAPI = async (searchText = '') => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_AUCTION_URL}auction-auto-suggest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        authorization: cookies.get('authToken') ?? '',
      },
      body: JSON.stringify({ searchText }),
    })
    const response = await res.json()
    if (response.data) {
      setCityData(response.data)
    }
    return response
  }
  const getCityData = async (searchText = '') => {
    let cityDataProp = []
    const response = await getCityAPI(searchText)
    cityDataProp = response.data
    if (response.data) {
      let selectedCity
      if (router.query.cityId) {
        selectedCity = cityDataProp && cityDataProp.filter((city) => city.cityId == router.query.cityId)[0]
        if (!selectedCity) {
          //fetchAuctions()
        } else {
          setUserSelectedCity(selectedCity || {})
          await getCityAPI(selectedCity.title)
        }
      }
      let seller_type = 'All'
      if(router?.query?.auction_seller) {
        seller_type = router.query.auction_seller === 'au-infath' ? 'Infath' : 'Wasalt'
      }
      pushDataLayerEventHandler(window, lang, 'Auction_SRP_Open', 'Auction Home', {
        City_Selected: (selectedCity && selectedCity.title) || '',
        Tab_Selected: router.query.tabType || `${t('auctionCommon.ALL')} ${t('auctionCommon.AUCTION')}`,
        seller_type
      })
      window.localStorage.setItem('cityDataList', JSON.stringify(cityDataProp))
    } else {
      setUserSelectedCity({})
    }
  }

  const getCitiesSeparated = () => {
    let popularCities = cityData.filter(({ popular = false }) => popular)
    let allCities = cityData.filter(({ popular = false }) => !popular)
    return { popularCities, allCities }
  }

  const renderThreeDots = () => (
    <div className={`${styles.menuDropdown} ${styles.isNotMobile}`}>
      <div className={styles.dropdownWrapper} ref={dropRef}>
        <div
          className={styles.dropdownHeader}
          onClick={(e) => {
            e.stopPropagation()
            toggleDropdown()
            pushDataLayerEventHandler(window, lang, 'auction_menu_clicked', 'auction')
          }}
        >
          <span className={styles.menutitle}>{t('auctionMenu.MENU_TITLE')}</span>
          <span className={styles.dropdown__icon}>{<IconoGraphy iconClass={'icon-three-dots'}></IconoGraphy>}</span>
          <span className={styles.dropdown__text}></span>
        </div>
        <ul className={`${styles[dropdownBody]} ${isOpen ? styles.open : ''}`}>
          <li
            className={`${styles.dropdownItems} ${styles.cursor_pointer}`}
            onClick={(e) => handleMenuOptionClick(e, 'contact', 'auction_more_menu_call_clicked', 'contact us')}
          >
            <IconContact />
            {t('auctionMenu.CONTACT_US')}
          </li>
          <li
            className={`${styles.dropdownItems} ${styles.cursor_pointer}`}
            onClick={(e) => {
              e.stopPropagation()
              onBtnClick('how_auctions_work')
            }}
          >
            <IconoGraphy iconClass={'icon-auction-outline'} iconColor={'color_gray_darker'} fontSize={'f_16'}></IconoGraphy>
            {t('auctionMenu.HOW_AUCTIONS_WORK')}
          </li>
          <li className={`${styles.dropdownItems} ${styles.cursor_pointer}`}
            onClick={(e) => {
              e.stopPropagation()
              onBtnClick('FAQ')
            }}
          >
            <IconoGraphy iconClass={'icon-question'} iconColor={'color_gray_darker'} fontSize={'f_16'}></IconoGraphy>
            {t('eWallet.FAQ')}
          </li>
          <li
            className={`${styles.dropdownItems} ${styles.cursor_pointer}`}
            onClick={(e) => handleMenuOptionClick(e, 'term', 'auction_more_menu_TNC_clicked', 'terms and conditions')}
          >
            <IconoGraphy iconClass={'icon-terms'} iconColor={'color_gray_darker'} fontSize={'f_16'}></IconoGraphy>
            {t('auctionMenu.TERMS_AND_CONDITIONS')}
          </li>
        </ul>
      </div>
    </div>
  )
  const onScroll = () => {
    if (!auctionHeaderRef.current) {
      return
    }
    const { top } = auctionHeaderRef.current.getBoundingClientRect()
    const val = top === 0
    if (headerReachedTop !== val) {
      setHeaderReachedTop(val)
      setIsHovering(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [headerReachedTop])

  useEffect(() => {
    getAuctionFilters() //here we are making client side API call to fetch filters
    getCityData()
    getAuctionData()

    if (window.localStorage.getItem('savedAuctionList')) {
      setSavedAuctions(
        window.localStorage
          .getItem('savedAuctionList')
          .split(',')
          .map((id) => id),
      )
    }

    if (cookies.get('userId')) {
      getAllSavedAuctionsProperties()
    } else {
      setLoadingFollowedAuction(false)
    }
  }, [])

  useEffect(() => {
    if (auctionCollection?.auctions && router.query && router?.query?.auction_seller !== ALL_SELLER_TYPE.WASALT) {
      if (
        Array.isArray(auctionCollection?.auctions) &&
        auctionCollection?.auctions.length <= 0 &&
        filtersFromAPI?.auction_seller.length > 1
      ) {
        const query = { auction_seller: ALL_SELLER_TYPE.WASALT }
        if (router?.query?.cityId) {
          query['cityId'] = router?.query?.cityId
        }
        router.replace({
          pathname: router.pathname,
          query,
        })
      }
    }
  }, [auctionCollection])

  /**@author Vikrant Sharma */
  // This function is used to iterate over selected filters from route and set active status to true and update text in dropdown
  const setFiltersFromRoute = (data = null, atIndex = null, val = null, params = null) => {
    const queryParams = params || router.query
    const _filterDropDowns = JSON.parse(JSON.stringify(data || defaultFilters))

    _filterDropDowns.forEach((dropdown) => {
      const dropdownValues = dropdown.values
      const selectedDropdownValues = queryParams[dropdown.filter_key] // Get query param from route for selected dropdown

      if (selectedDropdownValues) {
        const selectedDropdownValuesArray = selectedDropdownValues.split(',')
        const selectedDropdownNames = updateDropdownValues(dropdownValues, selectedDropdownValuesArray)
        updateDropdownTitle(dropdown, selectedDropdownNames)
      } else {
        dropdownValues.forEach((value) => {
          if (value.isActive) {
            dropdown.title = value.name
          }
        })
      }
    })

    // Handle dropdown opening/closing
    handleDropdownOpening(_filterDropDowns, atIndex, val)
    setFilterDropdown(_filterDropDowns)
  }

  const updateDropdownValues = (dropdownValues, selectedDropdownValuesArray) => {
    const selectedDropdownNames = []

    dropdownValues.forEach((value) => {
      value.isActive = selectedDropdownValuesArray.includes(value.slug)
      if (value.isActive) {
        selectedDropdownNames.push(value.name)
      }
    })

    return selectedDropdownNames
  }

  const updateDropdownTitle = (dropdown, selectedDropdownNames) => {
    dropdown.isActive = selectedDropdownNames.length > 0
    dropdown.title = selectedDropdownNames.slice(0, 2).join(', ')
    if (selectedDropdownNames.length > 2) {
      dropdown.title += '...'
    }
  }

  const handleDropdownOpening = (_filterDropDowns, atIndex, val) => {
    if (atIndex !== null) {
      _filterDropDowns.forEach((dropdown, i) => {
        if (val !== null) {
          dropdown.isOpen = i === atIndex ? val : false
        } else {
          dropdown.isOpen = false
        }
      })
    }
  }

  /**@author Mitesh Tagadiya */
  // The function handleVisibilityChange is used to set auctions coming from api on visiility change
  const handleVisibilityChange = async () => {
    const query = new URLSearchParams(window?.location?.search)
    const typeSeller = query.get('auction_seller') || ALL_SELLER_TYPE.ALL
    const status = query.get('auction_status')
    const cityId = query.get('cityId') || (userSelectedCity && userSelectedCity.cityId)
    const pageNo = query.get('page') || 0

    if (
      !document.hidden &&
      (window?.location?.search.includes('auction_status') ? typeof status !== 'undefined' : true) &&
      (window?.location?.search.includes('auction_seller') ? typeof typeSeller !== 'undefined' : true) &&
      (window?.location?.search.includes('cityId') ? typeof cityId !== 'undefined' : true) &&
      (window?.location?.search.includes('page') ? typeof pageNo !== 'undefined' : true)
    ) {
      const objectToSend = ObjectForAuctionList(status, pageNo, cityId, typeSeller)
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_AUCTION_API_URL_V2}auction-srp-list?${new URLSearchParams(
          objectRemoveEmptyData(objectToSend),
        )}`,
        {
          headers: {
            'Content-Type': 'application/json',
            locale: lang,
          },
        },
      )
      const response = await res.json()
      handleAuctionResponse(response)
    }
  }

  const ObjectForAuctionList = (status, pageNo, cityId, typeSeller) => {
    const objectToSend = {
      type: status ? status.split(',') : [],
      page: pageNo ? Number(pageNo) - 1 : 0,
      pageSize: 15,
      cityId: cityId === 'ALL' ? null : cityId,
      seller: typeSeller,
    }

    if (cityId == 272) {
      delete objectToSend.cityId
    }

    return objectToSend
  }

  const handleAuctionResponse = (response) => {
    if (response?.data) {
      setAuctionList(response?.data?.auctions)
      setSellerType(response.data.seller)
      setCount(response.data.count)
      setPage(router.query.page ? Number(router.query.page) : 1)
    } else {
      setAuctionList([])
      setSellerType('')
      setPage(0)
    }
  }

  /**@author Mitesh Tagadiya */
  // This useEffect is used to get auctions on visibility change
  useEffect(() => {
    document.addEventListener('visibilitychange', () => handleVisibilityChange(), false)
    return () => {
      document.removeEventListener('visibilitychange', null)
    }
  }, [])

  /**@author Vikrant Sharma */
  // This useEffect is used to set filter values based on route Params
  useEffect(() => {
    if (Object.keys(router?.query).length > 0 && !router?.query?.followAuctionId) {
      fetchAuctions(
        router.query.auction_seller || ALL_SELLER_TYPE.ALL,
        router.query.auction_status,
        router.query.cityId || (userSelectedCity && userSelectedCity.cityId),
      )
      setFiltersFromRoute()
    }
  }, [router])

  /**@author Vikrant Sharma */
  // This function is used to set the default filters based on filters which are received from API on client side
  useEffect(() => {
    if (filtersFromAPI) {
      for (const filter in filtersFromAPI) {
        let matchedFilterIndex = filterDropDowns.findIndex((val) => val.filter_key === filter)
        if (matchedFilterIndex > -1) {
          filterDropDowns[matchedFilterIndex].values = filtersFromAPI[filter].map((obj) => ({
            ...obj,
            isActive: false, // setting Infath as default seller type
          }))
        }
      }
      const parsedData = JSON.parse(JSON.stringify(filterDropDowns))
      setDefaultFilters(parsedData)
      setFiltersFromRoute(parsedData)
    }
  }, [filtersFromAPI])

  const isSearchOptionOpen = (isOpenProp) => {
    setIsDropdownOpen(isOpenProp)
    if (isFilterDropdownOpen && isOpenProp) {
      //if filter drop down is open then on click of search close filter dropdown
      handleFilterDropdown(false, true)
    }
  }

  // store position in sessionStorage
  const handleScrollPositionClick = () => {
    sessionStorage.setItem('scrollPosition', window.scrollY)
  }

  /**@author Vikrant Sharma */
  // This function is used to handle filter open and close state. It hides/shows dropdown if user clicks outside filter and apply or clear filter
  const handleFilterDropdown = (val, outsideClick = false, index = null) => {
    const hasNoInfathAuctions = index === 0 && filtersFromAPI['auction_seller']?.length === 1
    if (hasNoInfathAuctions) return
    setIsFilterDropdownOpen(val)
    if (!outsideClick) {
      // this condition handles the outside click event and show/hide backfade and dropdown
      setIsDropdownOpen(false)
      setFiltersFromRoute(null, index, val) // this func. is called to handle dropdown switch i.e. if user selects any value in dropdown and then click on other filter then we need to reset the filter
    } else {
      // if user clicks outside then choose the default selected filters from route
      setFiltersFromRoute()
    }
  }

  /**@author Vikrant Sharma */
  // This function is used to set the selected dropdown value and set their status to true/false
  const setSelectedDropdownValue = (value, filter_key) => {
    let _filterDropdown = [...filterDropdown]

    const selectedDropdownIndex = _filterDropdown?.findIndex((val) => val.filter_key === filter_key) // find the index of selected value from dropdown array

    const selectedDropdownValues = _filterDropdown[selectedDropdownIndex]?.values // this variable contains dropdown values
    const isMultiSelectDropdown = _filterDropdown[selectedDropdownIndex]?.multiSelect // this variable checks if dropdown is multiselect or not

    let selectedValues = []
    for (let i = 0; i < selectedDropdownValues?.length; i++) {
      let dropdownSelectedVal = _filterDropdown[selectedDropdownIndex].values[i]
      let isActive = dropdownSelectedVal['isActive']

      // this if block checks 2 conditions:
      // 1.) if value matches and dropdown is multiselect --> Toggle for multiselect
      // 2.) if value matches and dropdown is not multiselect and value is not active
      if (
        selectedDropdownValues[i].slug === value.slug &&
        (isMultiSelectDropdown || (!isMultiSelectDropdown && !isActive))
      ) {
        _filterDropdown[selectedDropdownIndex].values[i]['isActive'] = !isActive // on select make selected value active
        selectedDropdownValues[i]['isActive'] = !isActive
      } else if (!isMultiSelectDropdown && isActive && selectedDropdownValues[i].slug !== value.slug) {
        // if value doesn't match then check if dropdown type is not multiselect and active then make active selections inactive
        _filterDropdown[selectedDropdownIndex].values[i]['isActive'] = false
      }
      if (selectedDropdownValues[i]['isActive']) {
        selectedValues.push(selectedDropdownValues[i].slug) // push all selected values for a dropdown in selected
      }
    }
    selectedValues = selectedValues.toString()
    let selectedFilterDropdownValues = {}
    selectedFilterDropdownValues[filter_key] = selectedValues
    setSelectedFilterDropdown(selectedFilterDropdownValues)
    setFilterDropdown(_filterDropdown)
  }

  /**@author Vikrant Sharma */
  // This function is used to update query params and set values in route for applied dropdown
  const handleApplyFilter = () => {
    let queryParams = { ...router.query }
    let filter_key = Object.keys(selectedFilterDropdown)
    let value = Object.values(selectedFilterDropdown)?.[0]
    if (filter_key.length > 0 && value) {
      queryParams[filter_key[0]] = value
      delete queryParams['page'] // remove page no from route if user changes seller type
    } else {
      delete queryParams[filter_key] // if there is no value selected then delete key from query param
    }
    router.push({
      pathname: '/',
      query: queryParams,
    })
    handleFilterDropdown(false)

    if (filterDropdown[0].isOpen) {
      pushDataLayerEventHandler(window, lang, 'Seller_type_applied', 'Auction Home', {
        page_type: 'Auction_SRP',
        Seller_type: value === ALL_SELLER_TYPE.INFATH ? 'Infath' : 'Wasalt',
      })
    } else {
      pushDataLayerEventHandler(window, lang, 'Auction_status_applied', 'Auction Home', {
        page_type: 'Auction_SRP',
        auction_status: value,
      })
    }
  }

  /**@author Vikrant Sharma */
  // This function is used to update query params and set values in route fon clearing filter
  const handleClearFilter = (dropdownKey) => {
    handleFilterDropdown(false)
    setSelectedFilterDropdown({})
    let queryParams = { ...router.query }
    // delete key from query param
    delete queryParams[dropdownKey]
    fetchAuctions(queryParams?.auction_seller || ALL_SELLER_TYPE.ALL, queryParams?.auction_status, queryParams?.cityId)
    setTimeout(() => {
      setFiltersFromRoute(null, null, null, queryParams)
    }, 500)
    router.push({
      pathname: '/',
      query: queryParams,
    })
  }

  const isAnyFilterOpen = filterDropdown.filter((el) => el.isOpen === true)
  const imgFirstLoad = (auctionItem) => auctionItem?.groupImages || auctionItem?.property?.propertyFiles?.images

  const handleMenuOptionClick = (e, btnName, eventName, optionName) => {
    e.stopPropagation()
    onBtnClick(btnName)
    pushDataLayerEventHandler(window, lang, eventName, 'auction', {
      menu_option_clicked: optionName,
    })
  }
  
  const handleTermAndConditionClicked = (eventName, optionName) => {
    pushDataLayerEventHandler(window, lang, eventName, 'auction', {
      menu_option_clicked: optionName,
    })
    const baseURL = `${process.env.NEXT_PUBLIC_BASE_URL}${lang === 'en' ? '/en' : ''}/s/terms-n-conditions-for-auction`;

    if (typeof window !== 'undefined') {
      window.open(baseURL, '_blank');
    }
  }

  const isAuctionAvaiable = () => {
    return (
      auctionList?.length <= 0 &&
      (!router?.query?.auction_seller || router?.query?.auction_seller === ALL_SELLER_TYPE.WASALT)
    )
  }

  // Determine the content based on modalType
  const renderModalContent = () => {
    let modalContent
    if (modalType === 'contact') {
      modalContent = <ContactUs onClose={popupHandler} />
    } else if (modalType === 'term') {
      modalContent = <TermsNConditions termData={htmlContent} cssContent={cssContent} onClose={popupHandler} />
    } else if (modalType === 'FAQ') {
      modalContent = <EWalletFAQ onClose={popupHandler} faqs={faqs} />
    } else if (modalType === 'how_auctions_work') {
      modalContent = (
        <HowAuctionsWork
          termData={termData}
          onClick={
            isMobile
              ? pushDataLayerEventHandler(window, lang, 'how_auction_works_clicked', 'Auction Home', {
                  page_type: 'Auction_SRP',
                })
              : null
          }
          onClose={popupHandler}
        />
      )
    } else {
      modalContent = null
    }
    return modalContent
  }

  const renderFilterWrapper = () =>
    (isDropdownOpen || isAnyFilterOpen[0]?.isOpen) && (
      <div
        className={styles.cityselectoprMask + ` ${isAnyFilterOpen[0]?.isOpen ? styles.filterBackdrop : ''}`}
        ref={dropRef}
      ></div>
    )

  return (
    <>
      <Head>
        {auctionCollection?.auctions &&
          auctionCollection?.auctions?.length > 0 &&
          auctionCollection?.auctions
            .slice(0, isDesktopSSR ? 6 : 1)
            .map((item, index) => (
              <link
                key={index}
                rel='preload'
                fetchPriority='high'
                as='image'
                href={getImageLinks(imgFirstLoad(item), item, item?.seller)}
              />
            ))}
      </Head>

      <Layout
        headerCities={menuHeader}
        footerLinks={homeFooter}
        currentPage='auction-home-page'
        title={t('auctionHome.TITLE')}
        description={t('auctionHome.DESCRIPTION')}
        locale={locale}
      >
        <div
          className={`${styles.tags_cityselectopr_wrapper} ${headerReachedTop ? styles.auctionMenuFixed : ''}`}
          ref={auctionHeaderRef}
        >
          <div className={styles.inrContainer}>
            <AutoSearchDropDownNew
              cities={getCitiesSeparated()}
              userSelectedCity={userSelectedCity}
              onCitySelected={(city) => {
                let queryParams = { ...router.query, cityId: city.cityId }
                if (city.cityId == 'ALL') {
                  delete queryParams.cityId
                }
                if (queryParams?.toString()) {
                  // if router has page no then delete page no
                  delete queryParams.page
                }
                router.push({
                  pathname: '/',
                  query: queryParams,
                })
                if (city.cityId == 'ALL') {
                  fetchAuctions()
                }
                setUserSelectedCity(city)
              }}
              getCityAPI={getCityAPI}
              renderThreeDots={renderThreeDots}
              isSearchOptionOpen={isSearchOptionOpen}
              isFilterDropdownOpen={isFilterDropdownOpen}
            />
            <div className={styles.plpBox}>
              {filterDropdown.length == 0 && (
                <div className={styles.navTabs}>
                  <SkeletonTags />
                </div>
              )}
              <div className={styles.navTabs}>
                {filterDropdown?.map((dropdown, index) => (
                  <DropDown
                    key={index}
                    dropdownProps={dropdown}
                    handleDropdownClick={(val, outsideClick) => handleFilterDropdown(val, outsideClick, index)}
                    handleSelectedValue={setSelectedDropdownValue}
                    handleApply={handleApplyFilter}
                    handleClear={handleClearFilter}
                  />
                ))}
              </div>
            </div>

            <div className={`${styles.menuDropdown} ${styles.isNotDesktop}`}>
              <div className={styles.dropdownWrapper} ref={dropRef}>
                <div
                  className={styles.dropdownHeader}
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleDropdown()
                    pushDataLayerEventHandler(window, lang, 'auction_menu_clicked', 'auction home')
                  }}
                >
                  <span className={styles.menutitle}>{t('auctionMenu.MENU_TITLE')}</span>
                  <span className={styles.dropdown__icon}>
                    {<IconoGraphy iconClass={'icon-three-dots'}></IconoGraphy>}
                  </span>
                  <span className={styles.dropdown__text}></span>
                </div>
                <ul className={`${styles[dropdownBody]} ${isOpen ? styles.open : ''}`}>
                  <li
                    className={`${styles.dropdownItems} ${styles.cursor_pointer}`}
                    onClick={(e) => handleMenuOptionClick(e, 'contact', 'auction_more_menu_call_clicked', 'contact us')}
                  >
                    <IconoGraphy
                      iconClass={'icon-call-outline'}
                      iconColor={'color_gray_darker'}
                      fontSize={'f_16'}
                    ></IconoGraphy>
                    {t('auctionMenu.CONTACT_US')}
                  </li>
                  <li
                    className={`${styles.dropdownItems} ${styles.cursor_pointer}`}
                    onClick={(e) => {
                      e.stopPropagation()
                      onBtnClick('how_auctions_work')
                      pushDataLayerEventHandler(window, lang, 'how_auction_works_clicked', 'Auction Home', {
                        page_type: 'Auction_SRP',
                      })
                    }}
                  >
                    <IconoGraphy
                      iconClass={'icon-auction-outline'}
                      iconColor={'color_gray_darker'}
                      fontSize={'f_16'}
                    ></IconoGraphy>
                    {t('auctionMenu.HOW_AUCTIONS_WORK')}
                  </li>
                  <li
                    className={`${styles.dropdownItems} ${styles.cursor_pointer}`}
                    onClick={(e) => {
                      e.stopPropagation()
                      onBtnClick('FAQ')
                      pushDataLayerEventHandler(window, lang, 'FAQ_clicked', 'Auction Home', {
                        page_type: 'Auction_SRP',
                      })
                    }}
                  >
                    <IconoGraphy
                      iconClass={'icon-question'}
                      iconColor={'color_gray_darker'}
                      fontSize={'f_16'}
                    ></IconoGraphy>
                    {t('eWallet.FAQ')}
                  </li>
                  <li
                    className={`${styles.dropdownItems} ${styles.cursor_pointer}`}
                    onClick={() => handleTermAndConditionClicked('auction_more_menu_TNC_clicked', 'terms and conditions')}
                  >
                    <IconoGraphy
                      iconClass={'icon-terms'}
                      iconColor={'color_gray_darker'}
                      fontSize={'f_16'}
                    ></IconoGraphy>
                    {t('auctionMenu.TERMS_AND_CONDITIONS')}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {renderFilterWrapper()}
        {loading && auctionList?.length <= 0 ? (
          <div className={styles.textCenter}>
            {' '}
            <Image width='45' height='45' src={LoaderImage} alt='loading ...' />{' '}
          </div>
        ) : (
          <>
            {isAuctionAvaiable() && (
              <div className={styles.noAuctionContainer}>
                <h3 className={styles.noAuctionText}>{t('Details.NO_AUCTION_TEXT')}</h3>
              </div>
            )}
          </>
        )}
        <AuctionProvider>
        <div className={styles.grid_view_results}>
          {auctionList?.map((auction, index) => (
              <AuctionCard
              showStrip={true}
              key={auction.id}
              auctionData={auction}
              City_Selected={userSelectedCity.title}
              isFav={savedAuctions.includes(auction.auctionId)}
              hasFollowed={followedAuctions?.includes(auction.auctionId)}
              loadingFollowedAuction={loadingFollowedAuction}
              refetchAllAuctions={getAllSavedAuctionsProperties}
              getAcutionPropertyDetails={() =>
                auction?.sellerSlug === ALL_SELLER_TYPE.WASALT &&
                updateAuctionData(auction.id, index, cookies.get('authToken'), auctionList, setAuctionList, lang)
              }
              handleScrollPositionClick={handleScrollPositionClick}
              sellerType={sellerType}
              isHovering={isHovering}
              setIsHovering={setIsHovering}
              isDesktopSSR={isDesktopSSR}
              cardIndex={index}
            />
          ))}
        </div>
        </AuctionProvider>
        {auctionList?.length > 0 && (
          <Pagination
            onPageChange={(pageno) => {
              router.push({
                pathname: '/',
                query: { ...router.query, page: pageno },
              })
            }}
            data={auctionList}
            totalCount={count}
            currentPage={Number(page)}
            pageSize={15}
            routerPage={page}
            siblingCount={isMobile ? 0 : 1}
            hits={auctionList?.length}
            isMobileView={isMobile}
            isTabletView={isTablet}
            isOpenAppHeader={isOpenAppHeader}
          />
        )}

        {renderModalContent()}
      </Layout>
    </>
  )
}

export default App

export async function getServerSideProps(context) {
  const { query, locale } = context
  const uCookies = new UCookies(context.req.headers.cookie)
  const headerCookie = `i18next=${uCookies.cookies.i18next}`
  const reqHeaders = {
    'Content-Type': 'application/json',
    Cookie: headerCookie,
    locale: locale,
  }
  const isDesktopSSR = !isMobileSSR(context)
  let sellerType = query?.auction_seller
  let status = query?.auction_status

  let objectToSend = {
    type: status ? status.split(',') : [],
    page: query.page ? Number(query.page) - 1 : 0,
    pageSize: 15,
    seller: sellerType || ALL_SELLER_TYPE.ALL,
  }
  if (query.cityId) {
    objectToSend.cityId = query.cityId
  }

  const api_auction = process.env.NEXT_PUBLIC_AUCTION_INTERNAL_URL_V2 || process.env.NEXT_PUBLIC_AUCTION_API_URL_V2

  // later need to update api_auction for new filter API and set process.env.NEXT_PUBLIC_AUCTION_API_URL_V2
  const [menuResponse, auctionCollectionRes] = await Promise.all([
    getHeaderFooterLinks(locale),
    fetch(`${api_auction}auction-srp-list?${new URLSearchParams(objectRemoveEmptyData(objectToSend))}`, {
      headers: reqHeaders,
    }),
  ])
  const [auctionCollection] = await Promise.all([auctionCollectionRes.json()])
  return {
    props: {
      menuHeader: menuResponse?.navigationLinks || {},
      homeFooter: menuResponse?.footerLinks || {},
      locale,
      auctionCollection: { ...auctionCollection?.data },
      isDesktopSSR,
    },
  }
}
